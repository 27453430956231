import React from "react";
import { months } from "../shared/months";

function Tasks({ tasks }) {
  const Collection = () =>
    tasks.map((task, id) => {
      const t = new Date(task.date * 1000);
      const formattedDate =
        t.getDate() + " " + months[t.getMonth()] + " " + t.getFullYear();

      return (
        <tr key={id}>
          <th scope="row">{id + 1}</th>
          <td>{formattedDate}</td>
          <td>
            <div dangerouslySetInnerHTML={{ __html: task.description }} />
          </td>
          <td>{task.time_spent} minuten</td>
        </tr>
      );
    });

  return (
    <>
      <h3>Taken</h3>
      <table className="tasks">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Datum</th>
            <th scope="col">Omschrijving</th>
            <th scope="col">Tijdsduur</th>
          </tr>
        </thead>
        <tbody>
          <Collection />
        </tbody>
      </table>
    </>
  );
}

export default Tasks;
