export const ADD_CARDS = "ADD_CARDS";
export const ADD_CARD = "ADD_CARD";
export const CARDS_LOADING = "CARDS_LOADING";
export const CARDS_FAILED = "CARDS_FAILED";

export const UPDATE_USERNAME = "UPDATE_USERNAME";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_REMEMBER_ME = "UPDATE_REMEMBER_ME";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SET_TOKEN = "SET_TOKEN";

export const UPDATE_KEYWORD = "UPDATE_KEYWORD";
export const UPDATE_HIDE_EXPIRED = "UPDATE_HIDE_EXPIRED";

export const UPDATE_SHOW_MODAL = "UPDATE_SHOW_MODAL";
export const UPDATE_MODAL_DATA = "UPDATE_MODAL_DATA";
