import React from "react";
import closeIcon from "../images/close-icon.svg";
import Tasks from "./Tasks";

function Modal({
  title,
  timeRemaining,
  minutes,
  daysLeft,
  tasks,
  showModal,
  updateShowModal
}) {
  const timePercentage = Math.round((100 / minutes) * timeRemaining);
  const daysPercentage = Math.round((100 / 365) * daysLeft);

  return (
    <div
      role="dialog"
      aria-modal="true"
      tabIndex="-1"
      className={`modal ` + (showModal ? "" : "hidden")}
      onClick={e => {
        if (e.target.classList.contains("modal")) {
          updateShowModal(false);
        }
      }}
    >
      <div role="document" className="modal__dialog">
        <div className="modal__content">
          <div className="modal__header">
            <h3 className="modal__title">{title}</h3>
            <button
              type="button"
              onClick={() => updateShowModal(false)}
              className="modal__close"
            >
              <span aria-hidden="true">
                <img src={closeIcon} alt="Close" />
              </span>
              <span className="sr-only">Close</span>
            </button>
          </div>
          <div className="modal__body">
            <div className="progress-bars">
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={timeRemaining}
                  aria-valuemin="0"
                  aria-valuemax={minutes}
                  style={{ width: `${timePercentage}%` }}
                >
                  <span>
                    {timeRemaining} / {minutes} minuten
                  </span>
                </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  aria-valuenow={daysLeft}
                  aria-valuemin="0"
                  aria-valuemax="365"
                  style={{ width: `${daysPercentage}%` }}
                >
                  <span>{daysLeft} / 365 dagen</span>
                </div>
              </div>
            </div>

            <Tasks tasks={tasks} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
