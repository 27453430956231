import React, { PureComponent } from "react";
import Post from "./Post";

class Posts extends PureComponent {
  filterPosts(title) {
    return (
      title.toLowerCase().indexOf(this.props.search.keyword.toLowerCase()) !==
      -1
    );
  }

  showExpired(card) {
    if (this.props.search.hideExpired) {
      return card.time_remaining > 0 && card.days_left > 0;
    } else {
      return true;
    }
  }

  render() {
    const Posts = () =>
      this.props.posts.map((post, id) => {
        if (this.filterPosts(post.title) && this.showExpired(post.cards[0])) {
          return (
            <div key={id} className="col-lg-3 col-md-4 col-sm-6">
              <Post
                title={post.title}
                updateModalData={this.props.updateModalData}
                updateShowModal={this.props.updateShowModal}
                card={post.cards[0]}
                editLink={post.edit_link}
              />
            </div>
          );
        } else {
          return false;
        }
      });

    return <Posts />;
  }
}

export default Posts;
