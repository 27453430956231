import React from "react";
import Loader from "./Loader";

function Login({
  updatePassword,
  updateUsername,
  updateRememberMe,
  handleSubmit,
  username,
  password,
  rememberMe,
  errMess,
  isLoading
}) {
  return (
    <>
      <h1>Strippenkaartsysteem Zout&amp;Dijk Webdesign</h1>
      <p>Log in om de status van jouw strippenkaart(en) te bekijken.</p>

      <form onSubmit={handleSubmit} className="login-form">
        <div className="login-form__input">
          <input
            type="text"
            value={username}
            placeholder="Gebruikersnaam"
            onChange={e => updateUsername(e.target.value)}
          />
        </div>
        <div className="login-form__input">
          <input
            type="password"
            value={password}
            placeholder="Wachtwoord"
            onChange={e => updatePassword(e.target.value)}
          />
        </div>

        <div className="login-form__input login-form__input--checkbox">
          <label className="check-container">
            Onthoud mij
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={e => updateRememberMe(e.target.checked)}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="login-form__input login-form__input--submit">
          <input type="submit" value="Inloggen" />
          {isLoading ? <Loader /> : null}
        </div>
        {errMess ? (
          <div
            className="login-form__error"
            dangerouslySetInnerHTML={{ __html: errMess }}
          />
        ) : null}
      </form>
    </>
  );
}

export default Login;
