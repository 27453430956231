import React from "react";
import Card from "./Card";

function ClientView({ post, logout }) {
  const Cards = () =>
    post.cards.map((card, id) => {
      return (
        <Card card={card} isCollapsed={id === 0 ? false : true} key={id} />
      );
    });
  return (
    <>
      <div className="client-header">
        <h1>Strippenkaarten van {post.title}</h1>
        <button className="button" onClick={logout}>
          Uitloggen
        </button>
      </div>
      <Cards />
    </>
  );
}

export default ClientView;
