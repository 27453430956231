import * as ActionTypes from "./ActionTypes";

export const Search = (
  state = {
    keyword: "",
    hideExpired: true
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_KEYWORD:
      return {
        ...state,
        keyword: action.payload
      };
    case ActionTypes.UPDATE_HIDE_EXPIRED:
      return {
        ...state,
        hideExpired: action.payload
      };
    default:
      return state;
  }
};
