import React, { Component } from "react";
import Loader from "./Loader";
import ClientView from "./ClientView";
import AdminView from "./AdminView";

class Cards extends Component {
  componentDidMount() {
    this.props.getCards(this.props.token);
  }

  render() {
    if (this.props.isLoading) {
      return (
        <>
          <Loader />
          <h1>Strippenkaarten worden ingeladen</h1>
        </>
      );
    } else if (this.props.errMess) {
      return (
        <>
          <h1>Er was een probleem met het inladen</h1>
          <p>{this.props.errMess.message}</p>
        </>
      );
    } else if (this.props.cards.user_type === "client") {
      return (
        <ClientView
          logout={this.props.logout}
          post={this.props.cards.posts[0]}
        />
      );
    } else if (this.props.cards.user_type === "administrator") {
      return (
        <AdminView logout={this.props.logout} posts={this.props.cards.posts} />
      );
    } else {
      return <h1>Er is een onbekende fout bij het laden</h1>;
    }
  }
}

export default Cards;
