import React from "react";
import Posts from "./Posts";
import Modal from "./Modal";
import { connect } from "react-redux";

import {
  updateModalData,
  updateShowModal,
  updateKeyword,
  updateHideExpired
} from "../redux/ActionCreators";

const mapStateToProps = state => ({
  modal: state.modal,
  search: state.search
});

const mapDispatchToProps = dispatch => ({
  updateKeyword: keyword => dispatch(updateKeyword(keyword)),
  updateHideExpired: hideExpired => dispatch(updateHideExpired(hideExpired)),
  updateModalData: (title, timeRemaining, minutes, daysLeft, tasks) =>
    dispatch(updateModalData(title, timeRemaining, minutes, daysLeft, tasks)),
  updateShowModal: showModal => dispatch(updateShowModal(showModal))
});

function AdminView({
  posts,
  logout,
  updateKeyword,
  search,
  updateHideExpired,
  updateShowModal,
  modal,
  updateModalData
}) {
  return (
    <>
      <div className="admin-header">
        <div className="search">
          <input
            type="text"
            placeholder="Zoeken"
            value={search.keyword}
            onChange={e => updateKeyword(e.target.value)}
          />
        </div>

        <label className="check-container">
          Verberg verlopen strippenkaarten
          <input
            type="checkbox"
            id="rememberMe"
            checked={search.hideExpired}
            onChange={e => updateHideExpired(e.target.checked)}
          />
          <span className="checkmark"></span>
        </label>

        <button className="button" onClick={logout}>
          Uitloggen
        </button>
      </div>

      <div className="row">
        <Posts
          posts={posts}
          updateShowModal={updateShowModal}
          updateModalData={updateModalData}
          search={search}
        />
      </div>

      <Modal
        title={modal.data.title}
        timeRemaining={modal.data.timeRemaining}
        minutes={modal.data.minutes}
        daysLeft={modal.data.daysLeft}
        tasks={modal.data.tasks}
        showModal={modal.showModal}
        updateShowModal={updateShowModal}
      />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminView);
