import * as ActionTypes from "./ActionTypes";

export const Modal = (
  state = {
    data: {
      title: "",
      timeRemaining: null,
      minutes: null,
      daysLeft: null,
      tasks: []
    },
    showModal: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_SHOW_MODAL:
      return {
        ...state,
        showModal: action.payload
      };
    case ActionTypes.UPDATE_MODAL_DATA:
      return {
        ...state,
        data: action.payload
      };
    default:
      return state;
  }
};
