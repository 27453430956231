import * as ActionTypes from "./ActionTypes";
import { baseUrl } from "../shared/baseUrl";

export const authenticate = (username, password, rememberMe) => dispatch => {
  dispatch(loginLoading());

  return fetch(baseUrl + "jwt-auth/v1/token", {
    method: "POST",
    body: JSON.stringify({
      username: username,
      password: password
    }),
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(
      response => {
        if (response.ok) {
          return response;
        } else if (response.status === 403) {
          response
            .json()
            .then(response => dispatch(loginFailed(response.message)));
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      error => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then(response => response.json())
    .then(response => {
      // Add token to LocalStorage or SessionStorage
      if (rememberMe) {
        localStorage.setItem("wp_token", response.token);
      } else {
        sessionStorage.setItem("wp_token", response.token);
      }

      // Add token to store
      dispatch(setToken(response.token));
    })
    .catch(error => dispatch(loginFailed(error.message)));
};

const loginFailed = errmess => ({
  type: ActionTypes.LOGIN_FAILED,
  payload: errmess
});

const loginLoading = () => ({
  type: ActionTypes.LOGIN_LOADING
});

export const setToken = token => ({
  type: ActionTypes.SET_TOKEN,
  payload: token
});

export const getCards = token => dispatch => {
  dispatch(cardsLoading());

  return fetch(baseUrl + "jwt-auth/v1/token/validate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  })
    .then(
      response => {
        if (!response.ok) {
          // If not valid, logout
          localStorage.setItem("wp_token", "");
          sessionStorage.setItem("wp_token", "");
          dispatch(setToken(""));
        } else {
          // Else fetch cards
          dispatch(fetchCards(token));
        }
      },
      error => {
        throw new Error(error.message);
      }
    )
    .catch(error => {
      console.log(error);
    });
};

const fetchCards = token => dispatch => {
  return fetch(baseUrl + "cards/v1/user", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token
    }
  })
    .then(
      response => {
        if (response.ok) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      error => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then(response => response.json())
    .then(response => dispatch(addCards(response)))
    .catch(error => {
      dispatch(cardsFailed(error));
    });
};

export const cardsLoading = () => ({
  type: ActionTypes.CARDS_LOADING
});

export const cardsFailed = errmess => ({
  type: ActionTypes.CARDS_FAILED,
  payload: errmess
});

export const addCards = cards => ({
  type: ActionTypes.ADD_CARDS,
  payload: cards
});

export const updateUsername = username => ({
  type: ActionTypes.UPDATE_USERNAME,
  payload: username
});

export const updatePassword = password => ({
  type: ActionTypes.UPDATE_PASSWORD,
  payload: password
});

export const updateRememberMe = rememberMe => ({
  type: ActionTypes.UPDATE_REMEMBER_ME,
  payload: rememberMe
});

export const updateKeyword = keyword => ({
  type: ActionTypes.UPDATE_KEYWORD,
  payload: keyword
});

export const updateHideExpired = hideExpired => ({
  type: ActionTypes.UPDATE_HIDE_EXPIRED,
  payload: hideExpired
});

export const updateModalData = (
  title,
  timeRemaining,
  minutes,
  daysLeft,
  tasks
) => ({
  type: ActionTypes.UPDATE_MODAL_DATA,
  payload: {
    title: title,
    timeRemaining: timeRemaining,
    minutes: minutes,
    daysLeft: daysLeft,
    tasks: tasks
  }
});

export const updateShowModal = showModal => ({
  type: ActionTypes.UPDATE_SHOW_MODAL,
  payload: showModal
});
