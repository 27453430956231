import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getCards,
  authenticate,
  updateUsername,
  updatePassword,
  updateRememberMe,
  setToken
} from "../redux/ActionCreators";
import Login from "./Login";
import Cards from "./Cards";

const mapStateToProps = state => ({
  cards: state.cards,
  login: state.login
});

const mapDispatchToProps = dispatch => ({
  getCards: token => dispatch(getCards(token)),
  authenticate: (username, password, rememberMe) =>
    dispatch(authenticate(username, password, rememberMe)),
  updateUsername: username => dispatch(updateUsername(username)),
  updatePassword: password => dispatch(updatePassword(password)),
  updateRememberMe: rememberMe => dispatch(updateRememberMe(rememberMe)),
  setToken: token => dispatch(setToken(token))
});

class Main extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    const localToken = localStorage.getItem("wp_token");
    const sessionToken = sessionStorage.getItem("wp_token");

    if (localToken) {
      this.props.setToken(localToken);
    } else if (sessionToken) {
      this.props.setToken(sessionToken);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.authenticate(
      this.props.login.username,
      this.props.login.password,
      this.props.login.rememberMe
    );
  }

  logout() {
    this.props.setToken(null);
    localStorage.setItem("wp_token", "");
    sessionStorage.setItem("wp_token", "");
  }

  render() {
    return (
      <main className="container main">
        {this.props.login.token ? (
          <Cards
            getCards={this.props.getCards}
            token={this.props.login.token}
            logout={this.logout}
            cards={this.props.cards.cards}
            errMess={this.props.cards.errMess}
            isLoading={this.props.cards.isLoading}
          />
        ) : (
          <Login
            updateUsername={this.props.updateUsername}
            updatePassword={this.props.updatePassword}
            updateRememberMe={this.props.updateRememberMe}
            handleSubmit={this.handleSubmit}
            username={this.props.login.username}
            password={this.props.login.password}
            rememberMe={this.props.login.rememberMe}
            errMess={this.props.login.errMess}
            isLoading={this.props.login.isLoading}
          />
        )}
      </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
