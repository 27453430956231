import { createStore, combineReducers, applyMiddleware } from "redux";
import { Cards } from "./cards";
import { Login } from "./login";
import { Search } from "./search";
import { Modal } from "./modal";
import thunk from "redux-thunk";
import reduxLogger from "redux-logger";

export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      cards: Cards,
      login: Login,
      search: Search,
      modal: Modal
    }),
    applyMiddleware(thunk, reduxLogger)
  );

  return store;
};
