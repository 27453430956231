import * as ActionTypes from "./ActionTypes";

export const Cards = (
  state = {
    errMess: null,
    cards: [],
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.CARDS_LOADING:
      return {
        ...state,
        errMess: null,
        cards: [],
        isLoading: true
      };
    case ActionTypes.ADD_CARDS:
      return {
        ...state,
        errMess: null,
        cards: action.payload,
        isLoading: false
      };
    case ActionTypes.CARDS_FAILED:
      return {
        ...state,
        errMess: action.payload,
        cards: [],
        isLoading: false
      };
    default:
      return state;
  }
};
