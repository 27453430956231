import * as ActionTypes from "./ActionTypes";

export const Login = (
  state = {
    username: "",
    password: "",
    rememberMe: true,
    errMess: null,
    token: null,
    isLoading: false
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_USERNAME:
      return {
        ...state,
        username: action.payload
      };
    case ActionTypes.UPDATE_PASSWORD:
      return {
        ...state,
        password: action.payload
      };
    case ActionTypes.UPDATE_REMEMBER_ME:
      return {
        ...state,
        rememberMe: action.payload
      };
    case ActionTypes.LOGIN_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case ActionTypes.LOGIN_FAILED:
      return {
        ...state,
        errMess: action.payload,
        isLoading: false
      };
    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
        isLoading: false,
        errMess: null
      };
    default:
      return state;
  }
};
