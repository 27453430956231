import React, { useState } from "react";
import Tasks from "./Tasks";
import { months } from "../shared/months";

function Card({ card, isCollapsed }) {
  const timeRemaining = card.time_remaining;
  const minutes = card.minutes;
  const daysLeft = card.days_left;

  const timePercentage = Math.round((100 / card.minutes) * timeRemaining);
  const daysPercentage = Math.round((100 / 365) * daysLeft);

  const t = new Date(card.date * 1000);
  const formattedDate =
    t.getDate() + " " + months[t.getMonth()] + " " + t.getFullYear();

  const [collapsed, updateCollapsed] = useState(isCollapsed);

  return (
    <div className={`card-big ` + (collapsed ? "collapsed" : "")}>
      <div
        className="card-big__header"
        onClick={() => updateCollapsed(!collapsed)}
      >
        <h3 className="card-big__title">Datum van aanschaf: {formattedDate}</h3>
        <div className="card-big__expand-collapse">Expand/Collapse</div>
      </div>
      <div className="card-big__content">
        <div className="progress-bars">
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={timeRemaining}
              aria-valuemin="0"
              aria-valuemax={minutes}
              style={{ width: `${timePercentage}%` }}
            >
              <span>
                {timeRemaining} / {minutes} minuten
              </span>
            </div>
          </div>

          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              aria-valuenow={daysLeft}
              aria-valuemin="0"
              aria-valuemax="365"
              style={{ width: `${daysPercentage}%` }}
            >
              <span>{daysLeft} / 365 dagen</span>
            </div>
          </div>
        </div>
        <Tasks tasks={card.tasks} />
      </div>
    </div>
  );
}

export default Card;
