import React from "react";

function Post({ title, updateModalData, updateShowModal, card, editLink }) {
  const timeRemaining = card.time_remaining;
  const minutes = card.minutes;
  const daysLeft = card.days_left;

  const timePercentage = Math.round((100 / card.minutes) * timeRemaining);
  const daysPercentage = Math.round((100 / 365) * daysLeft);

  return (
    <div className="card-small">
      <div className="card-small__header">
        <h3 className="card-small__title">{title}</h3>
        <button
          className="card-small__details-link"
          onClick={() => {
            updateModalData(
              title,
              timeRemaining,
              minutes,
              daysLeft,
              card.tasks
            );
            updateShowModal(true);
          }}
        >
          Details
        </button>
        <a
          className="card-small__edit-link"
          href={decodeURI(editLink)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Edit
        </a>
      </div>
      <div className="card-small__content">
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={timeRemaining}
            aria-valuemin="0"
            aria-valuemax={minutes}
            style={{ width: `${timePercentage}%` }}
          >
            <span>
              {timeRemaining} / {minutes} minuten
            </span>
          </div>
        </div>

        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={daysLeft}
            aria-valuemin="0"
            aria-valuemax="365"
            style={{ width: `${daysPercentage}%` }}
          >
            <span>{daysLeft} / 365 dagen</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Post;
